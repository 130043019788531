import React from "react"
import { graphql } from "gatsby"
import Markdown from "react-markdown"
import SEO from "../components/seo"
import Layout from "../components/layout"
import InnerContentContainer from "../components/layout/innerContentContainer";
import FullWidthSection from "../components/layout/fullWidthSection";
import { blogRenderers } from "../constants/mdxRenderers"

const Page = ({ data }) => {
    const {
        page: {
          SEO: { titleseo, description },
            Content
        },
    } = data
  return (
    <>
          <SEO title={titleseo} description={description}/>
          <Layout>
              <FullWidthSection>
              <InnerContentContainer>
              <Markdown
          components={blogRenderers}
            children={Content}
          />
              </InnerContentContainer>
              </FullWidthSection>
          </Layout>

    </>
  )
}

export default Page

export const query = graphql`
  query GetSinglePage($slug: String) {
    page: strapiPage(slug: {eq: $slug}) {
        SEO {
          description
          titleseo
        }
        Content
      }
  }`

